/*eslint-disable no-unused-vars */
import { Button, IconButton, OutlinedInput, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import align_left_icon from "../../../assets/images/align-left-icon-request.png";
import calendar_icon from "../../../assets/images/calendar-icon-request.png";
import clipboard_icon from "../../../assets/images/clipboard-icon-request.png";
import image_icon from "../../../assets/images/image-icon-request.png";
import person_icon from "../../../assets/images/user-icon-request.png";
import {API} from "../../../redux/api";
import Styles from "./ApprovalPage.module.css";
// eslint-disable-next-line no-unused-vars
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import COMPANY_ICON from "../../../assets/images/employee-client.png";
import BRIEFCASE_ICON from "../../../assets/images/employee-job.png";
import USER_ICON from "../../../assets/images/employee-user.png";
import MWS_LOGO from "../../../assets/images/my-workspace.png";
import NRK_ICON from "../../../assets/images/nrk-employee.png";
import CLOCK_ICON from "../../../assets/images/clock-revision.png";
import CLOCK_REVISION_ICON from "../../../assets/images/clock-edit-revision.png";
// import PICTURE_ACTIVITY_1 from "../../../assets/images/picture-activity-1.png";
// import PICTURE_ACTIVITY_2 from "../../../assets/images/picture-activity-2.png";
import { ApprovalModalLayout, ApprovalModalRejectLayout } from "..";
// import ApprovalModalReject from "../ApprovalModalReject/ApprovalModalReject";
import styled from "@emotion/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import ModalSetPasswordSuccess from "./ModalSavePasswordSuccess/ModalSetPasswordSuccess";
import ResignForm from "./ResignForm/ResignForm";
import ResignFormViewByPdf from "./ResignFormViewByPdf/ResignFormViewByPdf";
import ModalNoteForm from "./ModalNoteForm/ModalNoteForm";
import UnderMaintenance from "../../_underMaintenancePage";
import MAINTENANCE_IMAGE from "../../../assets/images/UnderMaintenance.png";
import OvertimeForm from "./OvertimeForm/OvertimeForm";

const MuiTextField = styled(TextField)({
  backgroundColor: "#fbfbfb",
  borderRadius: 8,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      width: "100%",
    },
  },
});

// function useQuery() {
//   const { resign } = useLocation();

//   return React.useMemo(() => new URLSearchParams(resign), [resign]);
// }

export default function ApprovalPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const summaryValue = urlParams.get("summary");
  // const code = urlParams.get("code");
  const history = useHistory();
  const location = useLocation();
  // let query = useQuery();
  const { type, hash } = useParams();
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);
  const [isModalOpenReject, setIsModalOpenReject] = useState(false);
  const [isModalOpenNote, setIsModalOpenNote] = useState(false);
  const [dataEmployee, setDataEmployee] = useState();
  const [dataAttendance, setDataAttendance] = useState();
  const [prevDataAttendance, setPrevDataAttendance] = useState();
  const [dataUserName, setDataUserName] = useState();
  const [id, setId] = useState("");
  const [dataResign, setDataResign] = useState();
  const [dataOvertime, setDataOvertime] = useState();
  const [dataOvertimeName, setDataOvertimeName] = useState();


  const token = localStorage.getItem("accessToken");
  const [alert, setAlert] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const [successSavePassword, setSuccessSavePassword] = useState(false);
  const [resignSummary, setResignSummary] = useState();

  // const token = localStorage.getItem("accessToken");
  const [values, setValues] = useState({
    photoIndex: 0,
    isOpen: false,
    dataImage: true,
  });


  const [valuesEmployee, setValuesEmployee] = useState({
    photoIndex: 0,
    isOpen: false,
    imageIndex: null,
  });

  let isResignApproval = useMemo(() => {
    return location?.pathname?.split("/")[3];
  }, [location]);


  const uri = useMemo(() => {
    return dataAttendance?.images.map((el) => el.uri);
  }, [dataAttendance]);

  const uriEmployee = useMemo(() => {
    // let uri = [];
    // for (let i=0; i < dataEmployee?.profileChange?.attachments?.map((el) => el.uri).length; i++) {
    //   uri.push()
    // }
    return dataEmployee?.profileChange;
  }, [dataEmployee]);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const images = [uri?.[0]];


  const resignSum = type + "-summary";

  useEffect(() => {
    if (type === "attendance") {
      axios
        .get(`${API}/attendance/content/${hash}`)
        .then((res) => {
          setDataAttendance(res?.data?.response);
          setDataUserName(res?.data?.userName);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === "employee") {
      axios
        .get(`${API}/request/approval-profile/${hash}`)
        .then((res) => {
          setDataEmployee(res?.data?.response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === "set-password") {
      setId(hash);
    } else if (type === "resign") {
      axios
        .get(`${API}/resign/request/${hash}`)
        // .get(`${API}/resign-info/${hash}`)
        .then((res) => {
          setDataResign(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === 'overtime-approval-web') {
      axios
      .get(`${API}/attendance/content/${hash}`)
      .then((res) => {
        setDataOvertime(res?.data?.response);
        setDataOvertimeName(res?.data?.userName);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [hash, type, location, isResignApproval]);

  useEffect(() => {
    if (location.pathname.includes("summary")) {
      axios
        .get(`${API}/resign/summary/${isResignApproval}`)
        .then((res) => {
          setResignSummary(res?.data?.response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isResignApproval, location]);

  const prevId = useMemo(() => {
    let oldData = dataAttendance?.remark?.split("::");
    return oldData?.[1];
  }, [dataAttendance]);

  const isRevision = useMemo(() => {
    let oldData = dataAttendance?.remark?.split("::");
    return oldData?.[0];
  }, [dataAttendance]);

  useEffect(() => {
    if (type === "attendance") {
      axios
        .get(`${API}/attendance/${prevId}`)
        .then((res) => {
          setPrevDataAttendance(res?.data?.response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [prevId, type]);

  const isRemark = useMemo(() => {
    const notInclude = dataAttendance?.remark;
    const include = dataAttendance?.remark?.split("::");

    return { notInclude, include };
  }, [dataAttendance]);


  const isDateRequest = useMemo(() => {
    const startDate = moment(
      new Date(dataAttendance?.startTime).getTime()
    ).format("L");
    const endDate = moment(new Date(dataAttendance?.endTime).getTime()).format(
      "L"
    );

    return { startDate, endDate };
  }, [dataAttendance]);

  const isTimeRequest = useMemo(() => {
    const startTime = moment(
      new Date(dataAttendance?.startTime).getTime()
    ).format("LT");
    const endTime = moment(new Date(dataAttendance?.endTime).getTime()).format(
      "LT"
    );

    return { startTime, endTime };
  }, [dataAttendance]);

  const newUpdate = useMemo(() => {
    return dataEmployee?.profileChange?.newUpdate;
  }, [dataEmployee]);

  console.log(dataEmployee)


  const oldData = useMemo(() => {
    return dataEmployee?.user;
  }, [dataEmployee]);



  const handleApprove = async () => {
    setIsModalOpenSuccess(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleReject = async () => {
    setIsModalOpenReject(true);
  };

  const handleSubmitSetPassword = async (e) => {
    e.preventDefault();
    setAlert(null);
    try {
      if (password === confirmPassword) {
        // await updatePassword(token, id, {
        //   password: password
        // })
        const response = await axios.patch(
          `${API}/user/admin/${id}`,
          { password: password },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (response.data.code === 200) {
          setSuccessSavePassword(true);
        }
      } else {
        setAlert("Password harus sama");
      }
    } catch (err) {
      if (err.response.data.code === 409) {
        setAlert("Password sudah digunakan. Coba password lain.");
      } else {
        setAlert(err.response.data.error);
      }
    }
  };

  // if (type === "") {
  //   return (
  //     <div className={Styles.MaintenanceContainer}>
  //       <div className={Styles.MaintenanceMain}>
  //         <div className={Styles.MaintenanceImage}>
  //           <img src={MAINTENANCE_IMAGE} alt="" />
  //         </div>
  //         <div className={Styles.MaintenanceText}>
  //           <div className={Styles.MaintenanceLabel}>
  //             <span>Ooops!</span>
  //           </div>
  //           <div className={Styles.MaintenanceTextMain}>
  //             <span>Something went wrong</span>
  //           </div>
  //           <div className={Styles.MaintenanceTextLast}>
  //             <span>Check the URL for typos or errors and try again</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  if (location.pathname.includes("summary")) {
    return (
      <>
        {location.pathname.includes("summary") ? (
          <ResignFormViewByPdf data={resignSummary} />
        ) : null}
      </>
    );
  }

  if (type === "employee")
    return (
      <div className={Styles.ContainerEmployee}>
        <Helmet>
          <title>MyWorkSpace | Update Employee</title>
        </Helmet>
        <div className={Styles.Background} />
        <div className={Styles.WrapperEmployee}>
          <div className={Styles.WrapperFormEmployee}>
            {dataEmployee?.profileChange?.status === "APPROVED" && (
              <div className={Styles.StatusController}>
                <div className={Styles.StatusText}>
                  <span>Approved</span>
                  <span>You have approved this request</span>
                </div>
                <CheckIcon sx={{ color: "#22970F" }} />
              </div>
            )}
            {dataEmployee?.profileChange?.status === "REJECTED" && (
              <div className={Styles.StatusControllerReject}>
                <div className={Styles.StatusTextReject}>
                  <span>Rejected</span>
                  <span>You have rejected this request</span>
                </div>
                <CloseIcon sx={{ color: "#D52701" }} />
              </div>
            )}
            <div className={Styles.HeadingEmployee}>
              <div className={Styles.Text}>
                <span>Profile Update Request</span>
              </div>
              <div className={Styles.TextIcon}>
                <AccountCircleIcon />
                <span>Admin</span>
              </div>
            </div>
            <div className={Styles.Divider} />
            <div className={Styles.ValueControl}>
              <div className={Styles.Value}>
                <div className={Styles.DescIcon}>
                  <img src={USER_ICON} alt="user-img" />
                  <span>Name</span>
                </div>
                <div className={Styles.Desc}>
                  <span>{dataEmployee?.user?.name}</span>
                </div>
              </div>

              <div className={Styles.Value}>
                <div className={Styles.DescIcon}>
                  <img src={NRK_ICON} alt="user-img" />
                  <span>NRK</span>
                </div>
                <div className={Styles.Desc}>
                  <span>{dataEmployee?.user?.employeeId}</span>
                </div>
              </div>

              <div className={Styles.Value}>
                <div className={Styles.DescIcon}>
                  <img src={BRIEFCASE_ICON} alt="user-img" />
                  <span>Position</span>
                </div>
                <div className={Styles.Desc}>
                  <span>{dataEmployee?.user?.jobTitle}</span>
                </div>
              </div>

              <div className={Styles.Value}>
                <div className={Styles.DescIcon}>
                  <img src={COMPANY_ICON} alt="user-img" />
                  <span>Client</span>
                </div>
                <div className={Styles.Desc}>
                  <span>{dataEmployee?.user?.clientName}</span>
                </div>
              </div>
            </div>
            <div className={Styles.Divider} />

            <div className={Styles.ValueColumn}>
              <div className={Styles.ColumnChild}>
                <label>Data</label>
                <div className={Styles.Value}>
                  <ul>
                    {/* {
                    dummies?.map((each, idx) =>  
                    newUpdate && newUpdate[each.value] && (
                    <li key={ idx }>{ each?.name }</li>
                    ))
                  } */}
                    <li>KTP</li>
                    <li>Email</li>
                    <li>Phone Number</li>
                    <li>Date of Birth</li>
                    <li>Marital Status</li>
                    <li>NPWP</li>
                    <li>Bank Name</li>
                    <li>Bank Account Number</li>
                  </ul>
                </div>
              </div>

              <div className={Styles.ColumnChild}>
                <label>Previous Data</label>
                <div className={Styles.Value}>
                  <ul>
                    <li>{oldData?.idNumber ? oldData?.idNumber : "-"}</li>
                    <li>{oldData?.email ? oldData?.email : "-"}</li>
                    <li>{oldData?.phoneNumber ? oldData?.phoneNumber : "-"}</li>
                    <li>{oldData?.birthDate ? oldData?.birthDate : "-"}</li>
                    <li>
                      {oldData?.maritalStatus ? oldData?.maritalStatus : "-"}
                    </li>
                    <li>{oldData?.taxId ? oldData?.taxId : "-"}</li>
                    <li>
                      {oldData?.account?.bankName
                        ? oldData?.account?.bankName
                        : "-"}
                    </li>
                    <li>
                      {oldData?.account?.number
                        ? oldData?.account?.number
                        : "-"}
                    </li>
                  </ul>
                </div>
              </div>

              <div className={Styles.ColumnChild}>
                <label>New Data</label>
                <div className={Styles.Value}>
                  <ul>
                    <li>{newUpdate?.idNumber ? newUpdate?.idNumber : "-"}</li>
                    <li>{newUpdate?.email ? newUpdate?.email : "-"}</li>
                    <li>
                      {newUpdate?.phoneNumber ? newUpdate?.phoneNumber : "-"}
                    </li>
                    <li>{newUpdate?.birthDate ? newUpdate?.birthDate : "-"}</li>
                    <li>
                      {newUpdate?.maritalStatus
                        ? newUpdate?.maritalStatus
                        : "-"}
                    </li>
                    <li>{newUpdate?.taxId ? newUpdate?.taxId : "-"}</li>
                    <li>{newUpdate?.bankName ? newUpdate?.bankName : "-"}</li>
                    <li>
                      {newUpdate?.accountNumber
                        ? newUpdate?.accountNumber
                        : "-"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={Styles.ValueColumnResponsive}>
              <div className={Styles.ColumnChildResponsive}>
                <label>Data</label>
                <div className={Styles.ValueControlResponsive}>
                  <div className={Styles.WrapperResponsive}>
                    <label>KTP</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>
                            {oldData?.idNumber ? oldData?.idNumber : "-"}
                          </span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.idNumber ? newUpdate?.idNumber : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>Email</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>{oldData?.email ? oldData?.email : "-"}</span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.email ? newUpdate?.email : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>Phone Number</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>
                            {oldData?.phoneNumber ? oldData?.phoneNumber : "-"}
                          </span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.phoneNumber
                              ? newUpdate?.phoneNumber
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>Date of Birth</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>
                            {oldData?.birthDate ? oldData?.birthDate : "-"}
                          </span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.birthDate ? newUpdate?.birthDate : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>Marital Status</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>
                            {oldData?.maritalStatus
                              ? oldData?.maritalStatus
                              : "-"}
                          </span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.maritalStatus
                              ? newUpdate?.maritalStatus
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>NPWP</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>{oldData?.taxId ? oldData?.taxId : "-"}</span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.taxId ? newUpdate?.taxId : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>Bank Name</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>
                            {oldData?.account?.bankName
                              ? oldData?.account?.bankName
                              : "-"}
                          </span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.bankName
                              ? newUpdate?.bankName
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.WrapperResponsive}>
                    <label>Bank Account Number</label>
                    <div className={Styles.WrapperResponsive}>
                      <div className={Styles.ValueResponsive}>
                        <div className={Styles.DescIconResponsive}>
                          <span>Previous</span>
                          <span>
                            {oldData?.account?.number
                              ? oldData?.account?.number
                              : "-"}
                          </span>
                        </div>
                        <div className={Styles.DescResponsive}>
                          <span>New</span>
                          <span>
                            {newUpdate?.accountNumber
                              ? newUpdate?.accountNumber
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className={Styles.Value}>
                    <div className={Styles.DescIcon}>
                      <span>NRK</span>
                    </div>
                    <div className={Styles.Desc}>
                      <span>{dataEmployee?.user?.employeeId}</span>
                    </div>
                  </div>

                  <div className={Styles.Value}>
                    <div className={Styles.DescIcon}>
                      <span>Position</span>
                    </div>
                    <div className={Styles.Desc}>
                      <span>{dataEmployee?.user?.jobTitle}</span>
                    </div>
                  </div> */}

                  {/* <div className={Styles.Value}>
                    <div className={Styles.DescIcon}>
                      <span>Client</span>
                    </div>
                    <div className={Styles.Desc}>
                      <span>{dataEmployee?.user?.clientName}</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            {/* <div className={Styles.ValueControl}>
              <label htmlFor="">New Data Update</label>
              <div className={Styles.Value}>
                <div className={Styles.Keys}>
                  <span>NRK</span>
                  <span>KTP</span>
                  <span>Email</span>
                  <span>Phone Number</span>
                  <span>Date of Birth</span>
                  <span>Marital Status</span>
                  <span>NPWP</span>
                  <span>Bank Name</span>
                  <span>Bank Account Number</span>
                </div>

                <div className={Styles.Label}>
                  <span>{newUpdate?.employeeId ? newUpdate?.employeeId : "-"}</span>
                  <span>{newUpdate?.idNumber ? newUpdate?.idNumber : "-"}</span>
                  <span>{newUpdate?.email ? newUpdate?.email : "-"}</span>
                  <span>{newUpdate?.phoneNumber ? newUpdate?.phoneNumber : "-" }</span>
                  <span>{newUpdate?.birthDate ? newUpdate?.birthDate: "-"}</span>
                  <span>{newUpdate?.maritalStatus ? newUpdate?.maritalStatus: "-"}</span>
                  <span>{newUpdate?.taxId ? newUpdate?.taxId : "-"}</span>
                  <span>{newUpdate?.bankName ? newUpdate?.bankName : "-"}</span>
                  <span>{newUpdate?.accountNumber ? newUpdate?.accountNumber : '-'}</span>
                </div>
              </div>
            </div>

            <div className={Styles.ValueControlPrev}>
              <label>Previous Data</label>
              <div className={Styles.Value}>
                <div className={Styles.Keys}>
                  <span>NRK</span>
                  <span>KTP</span>
                  <span>Email</span>
                  <span>Phone Number</span>
                  <span>Date of Birth</span>
                  <span>Marital Status</span>
                  <span>NPWP</span>
                  <span>Bank Name</span>
                  <span>Bank Account Number</span>
                </div>
                <div className={Styles.Label}>
                  <span>{oldData?.employeeId ? oldData?.employeeId : "-"}</span>
                  <span>{oldData?.idNumber ? oldData?.idNumber : "-"}</span>
                  <span>{oldData?.email ? oldData?.email : '-'}</span>
                  <span>{oldData?.phoneNumber ? oldData?.phoneNumber : "-"}</span>
                  <span>{oldData?.birthDate ? oldData?.birthDate : "-"}</span>
                  <span>{oldData?.maritalStatus ? oldData?.maritalStatus : '-'}</span>
                  <span>{oldData?.taxId ? oldData?.taxId : "-"}</span>
                  <span>{oldData?.account?.bankName ? oldData?.account?.bankName : "-"}</span>
                  <span>{oldData?.account?.number ? oldData?.account?.number : "-"}</span>
                </div>
              </div>
            </div> */}

            <div className={Styles.PhotoController}>
              {dataEmployee?.attachments ? <label>File Upload</label> : null}
              {dataEmployee
                ? dataEmployee?.profileChange?.attachments?.map((each, idx) => (
                    <div className={Styles.InputGroupEmployee} key={idx}>
                      <div className={Styles.ColumnGroupEmployee}>
                        <div className={Styles.BorderBlueEmployee}>
                          <img
                            src={each?.uri}
                            alt="placeholder-img"
                            onClick={() =>
                              setValuesEmployee((prev) => ({
                                ...prev,
                                isOpen: each?.uri,
                                imageIndex: idx,
                                photoIndex: idx,
                              }))
                            }
                          />
                          <span>
                            {(each.type === "NPWP" && "NPWP") ||
                              (each.type === "BANK" && "Bank Save Book") ||
                              (each.type === "PROFILEPICTURE" &&
                                "Profile Picture")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
              {valuesEmployee?.isOpen && (
                <Lightbox
                  imageLoadErrorMessage="This image failed to load"
                  mainSrc={
                    uriEmployee?.attachments[valuesEmployee?.photoIndex]?.uri
                  }
                  onCloseRequest={() =>
                    setValuesEmployee((prev) => ({
                      ...prev,
                      isOpen: false,
                    }))
                  }
                />
              )}
            </div>
            {dataEmployee?.profileChange?.status === "APPROVED" ||
            dataEmployee?.profileChange?.status === "REJECTED" ? null : (
              <div className={Styles.ButtonGroupEmployee}>
                <Button onClick={() => setIsModalOpenReject(true)}>
                  Reject
                </Button>
                <Button onClick={handleApprove}>Process Data</Button>
              </div>
            )}
          </div>
        </div>

        {isModalOpenSuccess && (
          <ApprovalModalLayout
            active={isModalOpenSuccess}
            onOpen={setIsModalOpenSuccess}
            type={type}
            hash={hash}
            dataAttendance={dataAttendance}
            dataEmployee={dataEmployee}
            history={history}
          />
        )}

        {isModalOpenReject && (
          <ApprovalModalRejectLayout
            active={Boolean(isModalOpenReject)}
            onOpen={setIsModalOpenReject}
            type={type}
            hash={hash}
            dataAttendance={dataAttendance}
            dataEmployee={dataEmployee}
            history={history}
          />
        )}
      </div>
    );

  if (type === "resign")
    return (
      <>
        <ResignForm
          dataResign={dataResign}
          type={type}
          isModalOpenSuccess={isModalOpenSuccess}
          setIsModalOpenSuccess={setIsModalOpenSuccess}
          isModalOpenReject={isModalOpenReject}
          setIsModalOpenReject={setIsModalOpenReject}
          isModalOpenNote={isModalOpenNote}
          setIsModalOpenNote={setIsModalOpenNote}
        />

        {isModalOpenSuccess && (
          <ApprovalModalLayout
            active={isModalOpenSuccess}
            onOpen={setIsModalOpenSuccess}
            type={type}
            hash={hash}
            dataResign={dataResign}
            history={history}
          />
        )}

        {isModalOpenReject && (
          <ApprovalModalRejectLayout
            active={Boolean(isModalOpenReject)}
            onOpen={setIsModalOpenReject}
            activeRejection={isModalOpenNote}
            onOpenRejection={setIsModalOpenNote}
            type={type}
            hash={hash}
            dataResign={dataResign}
            history={history}
          />
        )}

        {isModalOpenNote && (
          <ModalNoteForm
            active={Boolean(isModalOpenNote)}
            onOpen={setIsModalOpenNote}
            type={type}
            dataResign={dataResign}
          />
        )}
      </>
    );

  if (type === "set-password") {
    return (
      <div className={Styles.SetPasswordContainer}>
        <Helmet>
          <title>MyWorkSpace | Set Password</title>
        </Helmet>
        <div className={Styles.TopBG}></div>
        <div className={Styles.SetPasswordBoxContainer}>
          <div className={Styles.PasswordContainerHeader}>
            <img src={MWS_LOGO} alt="mws" />
            {alert && <span className={Styles.Error}>{alert}</span>}
          </div>
          <div className={Styles.BoxTop}>
            <span>Buat Password</span>
            <span>Mohon isi password untuk bisa mulai akses akun anda</span>
          </div>
          <div className={Styles.FormContainer}>
            <form onSubmit={handleSubmitSetPassword} className={Styles.Form}>
              <div className={Styles.BoxForm}>
                <label>Password</label>
                <div style={{ position: "relative" }}>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    className={Styles.InputField}
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                    placeholder="Contoh: Password7!"
                    required
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className={Styles.IconButton}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>
              <div className={Styles.BoxForm}>
                <label>Konfirmasi Password</label>
                <div style={{ position: "relative" }}>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? "text" : "password"}
                    className={Styles.InputField}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    size="small"
                    placeholder="Konfirmasi Password"
                    required
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                    className={Styles.IconButton}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>
              <button type="submit">Buat Password</button>
              {/* </FormControl> */}
            </form>
          </div>
        </div>
        {successSavePassword && (
          <ModalSetPasswordSuccess
            active={successSavePassword}
            onOpen={setSuccessSavePassword}
            desc={"Password saved successfully."}
          />
        )}
      </div>
    );
  }

  if (type === "overtime-approval-web") {
    return (
      <>
        <OvertimeForm
          dataOvertime={dataOvertime}
          dataOvertimeName={dataOvertimeName}
          isModalOpenSuccess={isModalOpenSuccess}
          setIsModalOpenSuccess={setIsModalOpenSuccess}
          setIsModalOpenReject={setIsModalOpenReject}
        />
         {isModalOpenSuccess && (
          <ApprovalModalLayout
            active={isModalOpenSuccess}
            onOpen={setIsModalOpenSuccess}
            type={type}
            hash={hash}
            dataOvertime={dataOvertime}
            history={history}
          />
        )}

        {isModalOpenReject && (
          <ApprovalModalRejectLayout
            active={Boolean(isModalOpenReject)}
            onOpen={setIsModalOpenReject}
            type={type}
            hash={hash}
            dataOvertime={dataOvertime}
            history={history}
          />
        )}
      </>
    );
  }

  if(type === 'attendance') {
    return (
    <div className={Styles.Container}>
      <Helmet>
        <title>MyWorkSpace | Absence Revision</title>
      </Helmet>
      <div className={Styles.Wrapper}>
        {dataAttendance?.status === "APPROVED" && (
          <div className={Styles.StatusController}>
            <div className={Styles.StatusText}>
              <span>Approved</span>
              <span>You have approved this request</span>
            </div>
            <CheckIcon sx={{ color: "#22970F" }} />
          </div>
        )}
        {dataAttendance?.status === "REJECTED" && (
          <div className={Styles.StatusControllerReject}>
            <div className={Styles.StatusTextReject}>
              <span>Rejected</span>
              <span>You have rejected this request</span>
            </div>
            <CloseIcon sx={{ color: "#D52701" }} />
          </div>
        )}
        <div className={Styles.Heading}>
          <span>Request Form</span>
          <p>You can approve or reject the request</p>
        </div>
        <div className={Styles.ContentItem}>
          <div className={Styles.InputGroup}>
            <img src={clipboard_icon} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Requested</span>
  
              {isRevision && dataAttendance?.type === "ATTEND" ? (
                <p>ATTENDANCE (Clock in revision)</p>
              ) : (
                <p style={{ textTransform: "capitalize" }}>
                  {dataAttendance?.type ? dataAttendance?.type : "-"}
                </p>
              )}
  
              {/* <p style={{ textTransform: "capitalize" }}>
            {dataAttendance?.type ? dataAttendance?.type : "-"}
          </p> */}
            </div>
          </div>
          <div className={Styles.InputGroup}>
            <img src={person_icon} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Name</span>
              <p>{dataUserName?.name}</p>
            </div>
          </div>
          {/* <div className={Styles.InputGroup}>
        <img src={calendar_icon} alt="" className={Styles.IconContent} />
        <div className={Styles.ColumnGroup}>
          <span>Date request</span>
          <p>{`${isDateRequest?.startDate} - ${isDateRequest?.endDate}`}</p>
        </div>
      </div> */}
          {dataAttendance?.isRevision === true && dataAttendance?.type ? (
            <div className={Styles.InputGroup}>
              <img src={calendar_icon} alt="" className={Styles.IconContent} />
              <div className={Styles.ColumnGroup}>
                <span>Date request</span>
                <p>{`${isDateRequest?.startDate}`}</p>
              </div>
            </div>
          ) : (
            <div className={Styles.InputGroup}>
              <img src={calendar_icon} alt="" className={Styles.IconContent} />
              <div className={Styles.ColumnGroup}>
                <span>Date request</span>
                <p>{`${isDateRequest?.startDate} - ${isDateRequest?.endDate}`}</p>
              </div>
            </div>
          )}
          {prevDataAttendance?.startTime && (
            <div className={Styles.InputGroup}>
              <img src={CLOCK_ICON} alt="" className={Styles.IconContent} />
              <div className={Styles.ColumnGroup}>
                <span>Time In</span>
                <p>
                  {moment(
                    new Date(prevDataAttendance?.startTime).getTime()
                  ).format("LT")}
                </p>
              </div>
            </div>
          )}
  
          {dataAttendance?.isRevision && (
            <div className={Styles.InputGroup}>
              <img
                src={CLOCK_REVISION_ICON}
                alt=""
                className={Styles.IconContent}
              />
              <div className={Styles.ColumnGroup}>
                <span>Revision Time</span>
                <p>{`${isTimeRequest?.startTime}`}</p>
              </div>
            </div>
          )}
  
          <div className={Styles.InputGroup}>
            <img src={align_left_icon} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Description</span>
              {dataAttendance?.remark?.includes("::") ? (
                <p>
                  {dataAttendance?.remark
                    ? capitalizeFirstLetter(isRemark?.include[3])
                    : "-"}
                </p>
              ) : (
                <p>
                  {dataAttendance?.remark
                    ? capitalizeFirstLetter(isRemark?.notInclude)
                    : "-"}
                </p>
              )}
            </div>
          </div>
  
          {dataAttendance?.images
            ? dataAttendance?.images.map((each, idx) => (
                <div className={Styles.InputGroup} key={idx}>
                  <img src={image_icon} alt="" className={Styles.IconContent} />
                  <div className={Styles.ColumnGroup}>
                    <span>Photo</span>
                    <div className={Styles.BorderBlue}>
                      <img
                        src={each?.uri ? each?.uri : ""}
                        alt=""
                        onClick={() => setValues({ ...values, isOpen: true })}
                      />
                    </div>
                  </div>
                </div>
              ))
            : null}
          {values.isOpen && (
            <Lightbox
              mainSrc={images[values.photoIndex]}
              style={{ width: "400px", height: "400px" }}
              onCloseRequest={() => setValues({ ...values, isOpen: false })}
            />
          )}
        </div>
        {dataAttendance?.status === "APPROVED" ||
        dataAttendance?.status === "REJECTED" ? null : (
          <div className={Styles.ButtonGroupEmployee}>
            <Button onClick={() => setIsModalOpenReject(true)}>Reject</Button>
            <Button onClick={handleApprove}>Approve</Button>
          </div>
        )}
      </div>
  
      {isModalOpenSuccess && (
        <ApprovalModalLayout
          active={isModalOpenSuccess}
          onOpen={setIsModalOpenSuccess}
          type={type}
          hash={hash}
          dataAttendance={dataAttendance}
          dataEmployee={dataEmployee}
          history={history}
        />
      )}
  
      {isModalOpenReject && (
        <ApprovalModalRejectLayout
          active={Boolean(isModalOpenReject)}
          onOpen={setIsModalOpenReject}
          type={type}
          hash={hash}
          dataAttendance={dataAttendance}
          dataEmployee={dataEmployee}
          history={history}
        />
      )}
    </div>
    )
  }

  return (
    <div className={Styles.MaintenanceContainer}>
    <div className={Styles.MaintenanceMain}>
      <div className={Styles.MaintenanceImage}>
        <img src={MAINTENANCE_IMAGE} alt="" />
      </div>
      <div className={Styles.MaintenanceText}>
        <div className={Styles.MaintenanceLabel}>
          <span>Ooops!</span>
        </div>
        <div className={Styles.MaintenanceTextMain}>
          <span>Something went wrong</span>
        </div>
        <div className={Styles.MaintenanceTextLast}>
          <span>Check the URL for typos or errors and try again</span>
        </div>
      </div>
    </div>
  </div>
  );
}
